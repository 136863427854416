<template>
  <div class="profile-data">
    <a class="profile-data__img" @click="openPopup">Загрузить фотографию</a>
    <div class="profile-data__input">
      <Input
        header="Фамилия*"
        info-text="В случае смены фамилии, необходимо внести в данное поле актуальные данные"
        placeholder="Введите свою фамилию"
        v-model="formData.last_name"
        :error="v$.formData.last_name.$errors.length !== 0"
        canEdit="profile"
        editFieldName="last_name"
      />
      <Input
        header="Имя*"
        placeholder="Введите свое имя"
        v-model="formData.first_name"
        :error="v$.formData.first_name.$errors.length !== 0"
        canEdit="profile"
        editFieldName="first_name"
      />
      <Input
        header="Отчество (при наличии)"
        placeholder="Введите свое отчество"
        v-model="formData.middle_name"
        :error="v$.formData.middle_name.$errors.length !== 0"
        canEdit="profile"
        editFieldName="middle_name"
      />
      <Input
        header="Дата рождения*"
        placeholder="01.10.2004"
        type="date"
        :min="minBirthday"
        :max="maxBirthday"
        v-model="formData.birthday"
        :error="
          v$.formData.birthday.$errors.length !== 0 ||
          isAfterToday(formData.birthday)
        "
        :autocomplete="false"
        @mousedown="formData.birthday = ''"
        @blur="validateMinDate(formData.birthday)"
        canEdit="profile"
        editFieldName="birthday"
      />
      <div class="profile-data__radio">
        <TabsRadio
          :tabData="tabsRadioProfileData"
          v-model="formData.gender"
          :error="v$.formData.first_name.$errors.length !== 0"
          editFieldName="gender"
        />
      </div>
      <Input
        header="Номер телефона*"
        placeholder="+7 (917) 000-00-00"
        type="tel"
        :mask="'+7(###)-###-##-##'"
        v-model="formData.phone"
        :error="v$.formData.phone.$errors.length !== 0"
        canEdit="profile"
        editFieldName="phone"
      />
      <Input
        :placeholder="user.email"
        disabled
        header="Электронная почта"
        readonly
      />
      <Input
        :placeholder="`${user.telegram}`"
        disabled
        header="Telegram"
        readonly
      />
      <Input
        header="Вконтакте (по желанию)"
        placeholder="https://vk.com/"
        v-model="socials.vk.href"
      />
      <TextAreaBlock
        header="Краткая информация о себе (по желанию)"
        placeholder="Увлечения, главные заслуги"
        v-model="formData.about"
      />
    </div>
    <div class="profile-data__btn">
      <Button @click="saveInfo" :loading="isBtnLoading">
        Сохранить изменения
      </Button>
    </div>
    <div class="profile-data__tg">
      <div>Вы можете отвязать свой Telegram аккаунт</div>
      <Button secondary @click="telegramUnlink"> Отвязать </Button>
    </div>
  </div>
</template>

<script>
import tabsRadioProfileData from "@/enums/tabsRadioProfileData";
import Input from "@/components/Blocks/Input";
import Button from "@/components/Blocks/Button";
import TabsRadio from "@/components/Blocks/TabsRadio";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import moment from "moment";
import InputSearch from "@/components/Blocks/InputSearch";
import TextAreaBlock from "@/components/Blocks/TextAreaBlock";
import { setDisabledField } from "@/utils/helpers";

export default {
  name: "ProfileData",
  components: {
    TextAreaBlock,
    InputSearch,
    TabsRadio,
    Button,
    Input,
  },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      tabsRadioProfileData,
      trayValidationsTexts,
      isBtnLoading: false,
      isDateInvalid: false,
      formData: {
        last_name: "",
        first_name: "",
        middle_name: "",
        birthday: "",
        gender: "",
        phone: "",
        about: "",
      },
      socials: {
        vk: {
          href: "",
          type: "vk",
        },
      },
    };
  },
  watch: {
    user() {
      this.loadInfo();
    },
  },
  validations() {
    return { formData: validations.myProfileData };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.myProfileData;
    },
    minBirthday() {
      return moment().subtract(36, "years").add(1, "days").format("YYYY-MM-DD");
    },
    maxBirthday() {
      return moment().subtract(16, "years").format("YYYY-MM-DD");
    },
    ...mapState(["storage"]),

    ...mapState("user", ["user", "disabledFields", "setIsProfileMustEdit"]),
    ...mapGetters("user", ["infoFix"]),
  },
  methods: {
    async setFakeUserData() {
      Object.keys(this.formData).forEach((item) => {
        this.user[item] = this.formData[item];
      });

      if (
        this.user.socials.data.length > 0 &&
        this.user.socials.data.some((item) => {
          return item.type === "vk";
        })
      ) {
        Object.assign(
          this.user.socials.data.find((i) => {
            return i.type === "vk";
          }),
          this.socials.vk
        );
      } else {
        this.user.socials.data.push(this.socials.vk);
      }
    },
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupAvatarTerms",
        isShown: true,
        props: {},
      });
    },
    async saveInfo() {
      if (this.isDateInvalid) return;

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      let payloadObject = {};
      Object.keys(this.formData).map((item) => {
        if (this.formData[item]) {
          return (payloadObject[item] = this.formData[item]);
        }
      });
      payloadObject.birthday = `${this.formData.birthday} 00:00:00`;
      if (this.socials.vk.href) {
        payloadObject[`socials[vk][href]`] = this.socials.vk.href;
        payloadObject[`socials[vk][type]`] = this.socials.vk.type;
      }
      this.isBtnLoading = true;
      createRequest(requestConfigs.POSTSaveInfo, {
        jsonPayload: payloadObject,
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });

          this.setFakeUserData().then(async () => {
            if (this.infoFix) {
              let copyInfoFix = JSON.parse(JSON.stringify(this.infoFix)) || {};
              delete copyInfoFix.passport;

              Object.keys(copyInfoFix).forEach((item) => {
                if (
                  !copyInfoFix[item] ||
                  this.user[item] === copyInfoFix[item]
                ) {
                  delete copyInfoFix[item];
                }
              });

              let mustEdit =
                Object.values(copyInfoFix).length > 0
                  ? Object.values(copyInfoFix).every((item) => {
                      return item;
                    })
                  : false;

              await this.$store.commit("user/setIsProfileMustEdit", mustEdit);
            }
            this.setDisabledField(
              ["first_name", "last_name", "birthday"],
              "isProfileFilled",
              this.formData
            ).then(() => {
              this.$router.push({ name: "Education" });
            });
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error?.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    async loadInfo() {
      Object.keys(this.formData).forEach((item) => {
        return (this.formData[item] = this.user[item]);
      });
      this.formData.birthday = moment(this.user.birthday).format("YYYY-MM-DD");
      this.socials.vk.href = this.user.socials?.data?.find((item) => {
        return item.type === "vk";
      })?.href;
    },
    telegramUnlink() {
      createRequest(requestConfigs.GETTelegramUnlink)
        .then(() => {
          this.$store.dispatch("user/getUserInfo").then(() => {
            this.$router.push({ name: "NeedTelegram" });
          });
          this.$store.commit("pushToTray", {
            text: "Телеграм отвязан",
            type: "success",
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        });
    },
    isAfterToday(value) {
      return moment(value).isAfter(moment());
    },
    validateMinDate(value) {
      if (this.isAfterToday(value)) {
        this.$store.commit("pushToTray", {
          text: "Дата не может быть позже текущего дня",
          type: "error",
        });
        this.isDateInvalid = true;
      } else this.isDateInvalid = false;
    },
    setDisabledField,
    ...mapActions(["getPlaceName"]),
  },
  mounted() {
    this.loadInfo();
  },
};
</script>

<style lang="scss">
.profile-data {
  @include link();
  &__img {
    @include link();
    margin-bottom: 15px;
    display: none;
    @include adaptive(tablet-small) {
      display: block;
    }
  }
  &__input {
    max-width: 728px;
    & > div {
      &:last-child {
        margin: 0;
      }
    }
  }

  & &__radio {
    display: flex;
    gap: 12px;
    margin-bottom: 23px;
  }
  &__btn {
    margin-top: 16px;
  }
  &__tg {
    margin-top: 15px;
    div {
      margin-bottom: 15px;
    }
  }
}
</style>

export default [
  {
    text: "Мужской",
    value: "male",
  },
  {
    text: "Женский",
    value: "female",
  },
];
